<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <a-row type="flex" justify="space-between">
        <a-col :span="2">
          <a-button
            type="primary"
            @click="handleNewCard"
            class="mb-20 fl-right"
          >
            <template #icon><plus-outlined /></template>
            新建模板
          </a-button>
        </a-col>
      </a-row>
    </div>
  </div>
  <!--   根据节点的宽度来设置内容的宽度  1260小的/4   1680大的/5     -->
  <!-- :style="cardWidth: calc(100vh - 240px);"  小尺寸 -54 / 4  大尺寸 -70 / 6 -->
  <div
    class="tmpl-list"
    ref="cardRef"
    :style="{
      '--cardWidth': (cardRef?.offsetWidth - (col * 16 + 40 - 16)) / col + 'px',
    }"
  >
    <div
      v-for="item in templateList"
      :key="item.tempId"
      :class="{
        'tmpl-item': true,
        'item-active': active === item.tempId,
        disabled: item.stauts === '1',
      }"
      :search="search"
    >
      <div class="item-wrap" @click="handleClick(item)">
        <div class="item-box">
          <img :src="item.templateUrl" />
        </div>
        <div class="content">
          <div class="iss-data">
            <div class="item-title overflow">{{ item.templateName }}</div>
            <div class="item-title">创建时间：{{ item.updateTime }}</div>
          </div>
          <div>
            <a-dropdown class="dot">
              <template #overlay>
                <a-menu>
                  <a-menu-item key="2" @click="handleClickEdit(item)">
                    <export-outlined style="font-size: 15px" />
                    <a class="text-color">
                      <EditOutlined class="icon" />
                      编辑
                    </a>
                  </a-menu-item>
                  <a-menu-item key="1" v-if="item.id !== '1'">
                    <a-popconfirm
                      title="确认删除该标签？"
                      @confirm="handleConfirm(item)"
                    >
                      <a class="text-color">
                        <delete-outlined class="icon" />
                        删除
                      </a>
                    </a-popconfirm>
                  </a-menu-item>
                </a-menu>
              </template>
              <MoreOutlined class="iss-icon" />
            </a-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>

  <iss-create-card
    v-model:visible="viewVisible"
    :init-value="activeItem"
    ref="formRef"
    @fnOk="handleFnOkByEdit"
  />
</template>

<script>
import { reactive, toRefs, ref, onMounted, nextTick } from 'vue';
import {
  Row,
  Col,
  Dropdown,
  Menu,
  Popconfirm,
  message,
  // Form,
} from 'ant-design-vue';
import {
  PlusOutlined,
  DeleteOutlined,
  MoreOutlined,
  EditOutlined,
} from '@ant-design/icons-vue';
import IssCreateCard from './components/createCard';
import cardApi from '@/api/card';
import { debounce } from '@/utils';

export default {
  props: ['value'],
  components: {
    ARow: Row,
    ACol: Col,
    PlusOutlined,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    DeleteOutlined,
    MoreOutlined,
    EditOutlined,
    APopconfirm: Popconfirm,
    IssCreateCard,
  },

  setup(props, context) {
    const cardRef = ref();
    const formRef = ref(null);
    const state = reactive({
      templateList: [],
      active: props.value,
      viewVisible: false,
      activeItem: {},
      search: {},
      col: 4,
    });

    const cardList = () => {
      cardApi.getTemplateList('', {}).then(data => {
        state.templateList = data;
      });
    };
    cardList();

    onMounted(() => {
      window.addEventListener(
        'resize',
        debounce(() => {
          if (cardRef?.value?.offsetWidth > 1600) {
            state.col = 5;
          } else {
            state.col = 4;
          }

          let el = cardRef?.value?.querySelectorAll('.tmpl-item');
          if (el?.length) {
            el.forEach(item => {
              item.style.width =
                (cardRef?.value?.offsetWidth - (state.col * 16 + 6 + 40 - 12)) /
                  state.col +
                'px';
            });
          }
        }, 300)
      );

      nextTick(() => {
        if (cardRef.value.offsetWidth > 1600) {
          state.col = 5;
        }
      });
    });

    // const { resetFields } = Form.useForm(form, {});

    return {
      // resetFields,
      cardRef,
      ...toRefs(state),
      formRef,
      // form,

      handleClick: ({ tempId, stauts }) => {
        if (stauts === '0') {
          state.active = tempId;
          context.emit('update:value', tempId);
        }
      },

      // 弹窗保存方法
      handleFnOkByEdit: value => {
        let type = 'add';
        value.id && (type = 'update');
        cardApi[type]('', value).then(() => {
          message.success('操作成功');
          state.viewVisible = false;
          cardList();
        });
      },

      // 编辑
      handleClickEdit: item => {
        state.activeItem = item;
        // Object.assign(state.activeItem, item)
        state.viewVisible = true;
      },

      // 新建
      handleNewCard: () => {
        if (state.templateList.length === 20) {
          return message.error('模块数量超限（最多不能超20个）');
        }

        state.activeItem = {};
        state.viewVisible = true;
        formRef.value.resetFields();
      },

      // 删除
      handleConfirm: item => {
        cardApi.cardDelete('', { id: item.id }).then(() => {
          message.success('操作成功');
          cardList();
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  margin: 20px 20px 0px 20px;
  border-radius: 8px;
}
.tmpl-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 20px 20px;
  margin-top: 20px;
  text-align: center;
  max-height: calc(100vh - 172px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
  .tmpl-item {
    //height: 248px;

    width: var(--cardWidth);

    //height: 217px;
    position: relative;
    //width: calc(25% - 42px);
    //margin-right: 8px;
    //border: 1px solid #e5e6eb;
    //margin: 17px 0px 10px 15px;
    //background-color: @primary-2;
    border-radius: 4px;
    //transition: box-shadow 0.3s;
    //cursor: pointer;
    .item-wrap {
      // 灰色
      //border: 1px solid #e5e6eb;
      border-radius: 4px;
      transition: box-shadow 0.3s;
      cursor: pointer;
      position: relative;
      padding-top: 56.25%;
      padding-bottom: 88px;
      top: 0;
      height: 0;
      width: 100%;
      .item-box {
        position: absolute;
        top: 0;
        width: 100%;
        height: calc(100% - 88px);

        //width: 290px;
        //height: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px 4px 0px 0px;
        }
        .item-title {
          margin-top: 5px;
        }
      }
    }
    & .item-wrap:hover {
      //box-shadow: 0px 4px 10px 0px #e5e6ebaa;
      //background-color: @primary-2;
      //border: none;
    }
    &.item-active .item-wrap {
      border: none;
      box-shadow: 0px 8px 10px 0px #e5e6ebaa;
    }
    &.disabled {
      background-color: #f1f1f1;
      box-shadow: initial;
      cursor: not-allowed;
    }
  }
}
.content {
  //width: 290px;
  height: 88px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  .iss-data {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.icon {
  margin-right: 3px;
}
.content /deep/ .item-title {
  text-align: left;
}

.content /deep/ .overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dot /deep/ .text-color {
  color: #1d2129;
}
</style>
