<template>
  <a-modal
    :visible="visible"
    :title="form.id ? '编辑模板' : '新建模板'"
    width="600px"
    @ok="handleOk"
    @cancel="handleCancel"
  >

    <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="名称" v-bind="validateInfos.templateName">
        <a-input
          v-model:value="form.templateName"
          placeholder="请输入名称"
          autocomplete="new-password"
        />
      </a-form-item>

      <a-form-item label="模板底图" v-bind="validateInfos.templateUrl">
        <iss-image-upload
          v-model:value="form.templateUrl"
          :limit-size="10"
          list-type="picture-card"
          accept=".jpe,.jpeg,.jpg,.png"
        />
        <div class="font-color font-size">建议上传16:9，大小不超过1M，格式为JPG、JPEG或PNG的图片</div>
      </a-form-item>

      <a-form-item label="预览">
        <div
          class="yulan"
          :style="{ backgroundImage: 'url('+`${form.templateUrl}`+')' }"
        >
          <a-row>
            <a-col span="18">
              <div class="col-mt font">张三</div>
              <div class="col-mt font-color">易思智科技有限公司</div>
              <div class="col-mt font-color">运营经理</div>
            </a-col>

<!--          头像   -->
            <a-col span="6">
<!--              <a-descriptions :column="2">-->
<!--                <template #title>-->
                  <div class="iss-account">
                    <a-avatar  :size="60" class=" mb-8">
                      <UserOutlined />
                    </a-avatar>
                  </div>
<!--                </template>-->

<!--              </a-descriptions>-->
            </a-col>
          </a-row>
          <div class="borderBac"></div>
          <a-row>
            <a-col>
              <div class="col-mr font-color"> <PhoneOutlined class="icon"/>15019222000</div>
              <div class="col-mr font-color"> <MailOutlined class="icon"/> free@issmart.com.cn</div>
              <div class="col-mr font-color"> <EnvironmentOutlined class="icon"/> 广东-深圳</div>
            </a-col>
          </a-row>
        </div>
      </a-form-item>

    </a-form>


  </a-modal>
</template>

<script>
import { Avatar, Row, Col, Form } from 'ant-design-vue';
import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
  UserOutlined,
} from '@ant-design/icons-vue';
import { setAvatar } from '@/utils';
import { reactive, toRaw,  watch } from "vue";
import IssImageUpload from '@/components/imageUpload';
// import { validEmail, validMobile } from "@/utils/validation";
// import userApi from "@/api/user";

export default {
  components: {
    AAvatar: Avatar,
    // ADescriptions: Descriptions,
    AForm: Form,
    AFormItem: Form.Item,
    IssImageUpload,
    ARow: Row,
    ACol: Col,
    PhoneOutlined,
    MailOutlined,
    EnvironmentOutlined,
    UserOutlined,


  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const form = reactive({
      templateName: '',
      templateUrl: '',
      id: '',
      createTime: '',
      createUser: '',
      isDefault: '',
      updateTime: '',
      updateUser: '',

    })

    watch(
      () => props.initValue,
      initValue => Object.assign(form, initValue),
    );
    const required = { required: true, message: '不能为空' };
    const { validateInfos, validate, resetFields} = Form.useForm(form,
      {
        templateName: [required, { max: 50, message: '不可超过50个字' }],
        templateUrl: [required]
      });

    const handleCancel = () => {
      context.emit('update:visible', false);
    };


    return {
      form,
      setAvatar,
      validateInfos,
      resetFields,
      handleCancel,

      handleOk: () => {
        if (form.id) {
          validate().then(() => {
            context.emit('fnOk', toRaw(form));
          });

        } else {
          validate().then(() => {
            context.emit('fnOk', toRaw(form));
          });
        }
      },

    };
  },
};
</script>

<style lang="less" scoped>
.iss-account {
  text-align: center;
  margin-top: 4px;
  .account-name {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  .account-desc {
    color: @text-color;
    font-size: @font-size-base;
    font-weight: normal;
  }
}
.borderBac {
  border: 1px solid #e5e6eb;

}

.yulan {
  padding: 10px;
  background-size: cover;
  border-radius: 8px;
  box-shadow: @box-shadow-base;

}

.col-mr {
  margin: 5px;
}
.col-mt {
  margin: 3px;
}
.icon {
  margin-right: 5px;
}
.font-color {
  color: #86909C;
}
.font-size {
  font-size: 12px;
}
.font {
 color: #1D2129;
}
</style>
